<template>
  <div>
    <v-alert :value="showCountdownWidget" prominent outlined text>
      <v-row align="center">
        <v-col class="grow">
          Your free trial has expired. You can still use the app, but a
          countdown will appear on your charts. To remove the countdown, please
          upgrade your plan.
        </v-col>
        <v-col class="shrink">
          <v-btn @click="goPlans"> Select your plan </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  computed: {
    showCountdownWidget() {
      return (
        this.$store.getters.getShopProfile.popup_setting_show_message ===
        "countdown"
      );
    },
  },
  components: {
    CountdownWidget: require("@/components/Home/CountdownWidget.vue").default,
  },
  methods: {
    goPlans() {
      this.$router.push({ name: "Plans" });
    },
  },
};
</script>
