<template>
  <v-banner color="#F9D9A9" class="my-2" single-line v-if="has_not_rated_app">
    <div class="text-overline mb-1" style="font-weight: bold">
      YOUR REVIEWS MEAN THE WORLD TO US
    </div>

    <template v-slot:actions>
      <v-rating
        v-model="appRating"
        color="yellow darken-3"
        background-color="#2A3027"
        empty-icon="$ratingFull"
        hover
      ></v-rating>
      <v-btn
        color="#42343A"
        text
        :disabled="!appRating"
        :href="urlAfterRating"
        target="_blank"
        @click="saveRating"
        ><a style="color: #42343a">Rate</a>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  components: {
    RatingWidget: require("@/components/Home/RatingWidget.vue").default,
  },
  methods: {
    alert() {
      alert("Hello, World!");
    },
    saveRating() {
      this.$store.commit("updateShopProfile", {
        ...this.$store.getters.getShopProfile,
        app_rating: this.appRating,
        has_rated_app: true,
      });
      this.$store.commit("saveShopProfile", {
        app_rating: this.appRating,
        has_rated_app: true,
      });
    },
  },
  computed: {
    appRating: {
      get() {
        return this.$store.getters.getShopProfile.app_rating;
      },
      set(val) {
        this.$store.commit("updateShopProfile", {
          ...this.$store.getters.getShopProfile,
          app_rating: val,
        });
      },
    },
    has_not_rated_app() {
      return !this.$store.getters.getShopProfile.has_rated_app;
    },
    urlAfterRating() {
      return this.appRating < 5
        ? "https://docs.google.com/forms/d/e/1FAIpQLSfo4KleJ1RPFsIciRVG8oPq7-HwVPx93_Zaew-xmLpTmnVf0Q/viewform?embedded=true"
        : "https://apps.shopify.com/smartsize?locale=en#modal-show=ReviewListingModal";
    },
  },
};
</script>
