<template>
  <div>
    <div class="stick-nav">
      <Header></Header>
      <CountdownWidget></CountdownWidget>

      <v-snackbar
        class="mb-14"
        :timeout="3000"
        color="#000000"
        v-model="alert"
        dark
      >
        <div class="text-center" style="font-weight: bold; letter-spacing: 2px">
          {{ alertdata }}
        </div>
        <template v-slot:actions>
          <v-btn color="indigo" @click="alert = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <v-text-field
        class=""
        flat
        label="Search"
        prepend-inner-icon="mdi-magnify"
        solo-inverted
        color="cyan"
        v-model="search"
        clearable
        @click:clear="clearSearch"
        @input="changeType"
      ></v-text-field>
    </div>

    <v-row
      ><v-col cols="12" lg="9">
        <v-divider class="dividerelena"></v-divider>

        <v-list id="size-charts">
          <SizeChartLine
          class="rowMaxHeight"
            @alert_data="trigger_alert"
            @canpublish="publish"
            v-for="SizeChart in this.items"
            :key="SizeChart.id"
            :sizeChart="SizeChart"
            @update_chart_state="update_chart_state"
          />
        </v-list>
        <infinite-loading
          :identifier="this.infiniteId"
          ref="infiniteLoading"
          @infinite="onInfiniteScroll" />

        <v-divider class="dividerelena"></v-divider
      ></v-col>

      <v-col cols="12" lg="3">
        <v-card class="pa-5 d-flex flex-column align-center">
          <div class="text-overline mt-3" style="font-weight: bold">
            ANY QUESTIONS?
          </div>

          <v-list-item-subtitle class="mt-5 card-text text-wrap">
            Our team will provide fast support and resolution to your questions.
          </v-list-item-subtitle>
          <v-card-actions class="mt-5 flex-wrap">
            <a href="mailto:team@smartsize.io"
              ><v-btn color="black" text> SEND US AN EMAIL</v-btn></a
            ></v-card-actions
          >
        </v-card>

        <v-card class="my-2 px-5 pb-5 d-flex flex-column align-center">
          <v-card-title class="text-overline mt-3" style="
          font-weight:bold">Fordeer</v-card-title>
          <v-card-subtitle>Invoice Order Printer</v-card-subtitle>
       
          <img src="/logofn12erf-light.png" alt="Logo" height="100" width="100">


          <v-list-item-subtitle class="mt-5 card-text text-wrap">
            Fully customize your brand invoices and streamline the Shopify invoicing process. Make your brand stand out with PDF invoices. 
          </v-list-item-subtitle>
          <v-card-actions class="mt-5 flex-wrap">
            <a href="https://apps.shopify.com/fordeer-data-export?utm_source=smartsize&utm_medium=inapp&utm_campaign=partner" target="_blank"
              ><v-btn color="black" text> Try for FREE</v-btn></a
            ></v-card-actions
          >
        </v-card>
        <v-card class="my-2 px-5 py-8">
          <v-rating
            color="black"
            background-color="black"
            dense
            size="24"
            value=5
            readonly
          ></v-rating>

          <v-list-item-subtitle class="mt-8 card-text text-wrap">
            I have been looking for a sizing chart that doesn't look cheap and
            does not have a "powered by" sign. This is the easiest to add and
            use, best-looking sizing chart I could have found!!
            <p class="mt-5">United States</p>
          </v-list-item-subtitle>
        </v-card>
        <v-card class="my-2 px-5 py-8">
          <v-rating
            color="black"
            background-color="black"
            dense
            size="24"
            value="5"
            readonly
          ></v-rating>

          <v-list-item-subtitle class="mt-8 card-text text-wrap">
            Excellent app and has amazing customer service that responds fast
            and helps me solve my problems and issues.
            <p class="mt-5">United Kingdom</p>
          </v-list-item-subtitle>
        </v-card>

        <v-card class="my-2 px-5 py-8">
          <v-rating
            color="black"
            background-color="black"
            dense
            size="24"
            value="5"
            readonly
          ></v-rating>

          <v-list-item-subtitle class="mt-8 card-text text-wrap">
            very fast problem solving, i love it and would recommend it to
            everyone. great customer service.

            <p class="mt-5">Estonia</p>
          </v-list-item-subtitle>
        </v-card>

        <v-card class="mb-16 my-2 pa-5">
          <v-rating
            color="black"
            background-color="black"
            dense
            size="24"
            value="5"
            readonly
          ></v-rating>

          <v-list-item-subtitle class="mt-8 card-text text-wrap">
            The application is perfect, fast and intuitive, the support service
            is even better, since they help us with every little change or
            customization.

            <p class="mt-5">Spain</p>
          </v-list-item-subtitle>
        </v-card></v-col
      ></v-row
    >

    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-show="!!message"
          class="pa-4 black--text"
          v-html="message"
        ></v-card-text>
        <v-card-actions class="pt-5">
          <v-btn
            v-if="!options.noconfirm"
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click.native="cancel"
            >Cancel</v-btn
          >
          <v-btn
            color="cyan"
            class="body-2 font-weight-bold"
            outlined
            @click.native="agree"
            >Link to products</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "./ListSizeCharts/ListSizeChartsHeader";
import NewPricing from "@/components/Home/NewPricing.vue";
import RatingWidget from "@/components/Home/RatingWidget.vue";
import CountdownWidget from "@/components/Home/CountdownWidget.vue";
import NoSizeCharts from "@/components/Home/NoSizeCharts";
import InfiniteLoading from "vue-infinite-loading";

import Vue from "vue";

Vue.use(InfiniteLoading, {
  props: {
    spinner: "default",
    /* other props need to configure */
  },
  slots: {
    noMore: "", // you can pass a string value
    error: "Error retrieving your charts - please refresh the page",
    noResults: "- - -", // you also can pass a Vue component as a slot
  },
});

export default {
  components: {
    SizeChartLine: require("./SizeChartLine.vue").default,
    Header,
    NewPricing,
    CountdownWidget,
    InfiniteLoading,
  },
  data() {
    return {
      //sizeCharts: [],
      alert: false,
      alertdata: "",
      type: "success",
      title: "",
      message: "",
      dialog: false,
      resolve: null,
      options: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 8000,
        noconfirm: false,
      },
      search: "",

      pageSize: 5,
    };
  },
  created() {},
  mounted() {},
  watch: {
    alert(val) {
      if (val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  methods: {
    onInfiniteScroll($state) {
      // Load more data from Vuex
      const itemsFromVuex = this.filteredItems.slice(
        this.$store.getters.getInfiniteLoading.itemCount,
        this.$store.getters.getInfiniteLoading.itemCount + this.pageSize
      );

      if (itemsFromVuex.length) {
        this.$store.dispatch("updateInfiniteLoading", {
          ...this.$store.getters.getInfiniteLoading,
          itemCount: (this.$store.getters.getInfiniteLoading.itemCount +=
            itemsFromVuex.length),
        });

        this.$store.dispatch("addItemToInfiniteLoading", itemsFromVuex);
        $state.loaded();
      } else {
        // No more data available
        $state.complete();
      }
    },
    changeType() {
      this.$store.dispatch("resetInfiniteLoading");
    },
    goNew() {
      this.$router.push({ name: "Templates" });
    },
    async fetch_user_charts() {
      //this.$store.dispatch("fetchSizeCharts");
    },
    async update_chart_state(chartInList) {
      this.$store.state.sizeCharts = this.$store.state.sizeCharts.map((chart) =>
        chart.id === chartInList.id ? chartInList : chart
      );
    },
    trigger_alert(alertdata) {
      this.type = alertdata.status;
      this.alertdata = alertdata.msg;
      this.alert = true;
    },
    dismissible_close() {
      this.alert = false;
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    async publish(id) {
      //console.log(this.sizeCharts);
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/template/publish", {
          params: { chart_id: id, shop: localStorage.getItem("shop") },
        })
        .then((response) => (this.chart = response.data))
        .catch((error) => console.log(error));
      //console.log(this.chart);
      if (this.chart.type == "not_linked") {
        if (
          await this.open(
            "Remember",
            "You must have at least one product linked before publishing."
          )
        ) {
          //console.log("Move to Product assign");
          this.chart = this.chart.chart;
          if (this.chart.template_text != null) {
            this.chart.template_text = this.chart.template_text.replace(
              /\\n/g,
              ""
            );
          }
          this.currentChart = {
            chart_id: this.chart.template_chart_id,
            id: this.chart.id,
            title: this.chart.name,
            published: this.chart.published,
            content: this.chart.chart_text,
          };
          localStorage.setItem("chartdata", JSON.stringify(this.currentChart));
          this.$store.dispatch("fetchChartTableContent", this.currentChart.id);
          this.$router.push({ name: "LinkProducts" });
        }
      } else if (this.chart.type == "plan") {
        this.trigger_alert(this.chart);
      }

      this.$store.dispatch("upsertSizeChart", this.chart.chart);
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    infiniteId() {
      return this.$store.getters.getInfiniteId;
    },
    items() {
      return this.$store.getters.getInfiniteLoading.items;
    },
    isDashboardLoading() {
      return this.$store.getters.getisDashboardLoading;
    },
    filteredItems() {
      return this.$store.getters.getSizeCharts.filter((SizeChart) => {
        if (!this.search) return this.$store.getters.getSizeCharts;
        return SizeChart.title
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    showNewPricing() {
      return (
        this.$store.getters.getShopProfile.plan_id == 2 || // Plan = Free 4 charts
        this.$store.getters.getShopProfile.plan_id == 4 || // Plan = Free Trial
        this.$store.getters.getShopProfile.plan_id == 5 // Plan = Free
      );
    },
    showCreateButton() {
      return (
        !this.isDashboardLoading && //Dashboard is not loading
        this.$store.state.sizeCharts.length == 0 //Chart list is empty
      );
    },
  },
};
</script>

<style scoped>
.stick-nav {
  position: sticky;
  top: 0px;
  background: #f3f5f7;
  z-index: 200;
  padding-top: 50px;
}
.newChart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  .newChart {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  #size-charts {
    /* background: red; */
    max-width: 90%;
    margin: auto;
    width: 100%;
  }
}
.dividerelena {
  border-color: #77746f !important;
  border-width: 1px !important;
  margin-top: 0px;
  border-radius: 30px;
}

.black-text {
  color: black;
}
</style>
