<template>
  <div class="text-center">
   
  </div>
</template>

<script>
export default {
  components: {
    CreateChart: require("@/components/Home/CreateChart.vue").default,
  },

};
</script>
