<template>
  <div>
    <v-list shaped id="chart-list">
      <v-list-item-group light>
        <v-list-item inactive>
          <v-list-item-content>
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'Edit',
                query: {
                  source: 'chart',
                  id: sizeChart.id,
                  type: sizeChart.type,
                },
              }"
            >
              <v-list-item-title
                class="ma-1 d-flex flex-column justify-center"
                style="text-align: left"
              >
                {{ sizeChart.title }}
              </v-list-item-title>
            </router-link>
          </v-list-item-content>
          <!-- cm/in checkbox toggle -->

          <!-- Published / Unpublished label-->
          <v-list-item-content class="mr-4 d-flex justify-end size-chart-content">
            <div class="d-flex align-center">
              <v-list-item-title class="mx-4">
                {{ publish ? 'Published' : 'Unpublished' }}
              </v-list-item-title>
              <v-switch
                inset
                v-model="publish"
                color="cyan"
                :disabled="disabled"
                @click.stop="can_publish(sizeChart.id)"
              >
              </v-switch>
            </div>
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'Edit',
                query: {
                  source: 'chart',
                  id: sizeChart.id,
                  type: sizeChart.type,
                },
              }"
            >
              <v-icon>mdi-pencil</v-icon></router-link
            >
            <v-icon @click="duplicateSizeChart(sizeChart.id)">mdi-content-copy</v-icon>
            <v-icon @click="delRecord(sizeChart.id)">mdi-delete</v-icon>
            <!-- <v-icon @click="$router.push('/link') ">mdi-link</v-icon> -->
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @keydown.enter="agree"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-show="!!message"
          class="pa-4 black--text"
          v-html="message"
        ></v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!options.noconfirm"
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click.native="cancel"
            >Cancel</v-btn
          >
          <v-btn
            color="cyan"
            class="body-2 font-weight-bold"
            outlined
            @click.native="agree"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      publish: this.sizeChart.published,
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      allow_metric_conversion: this.sizeChart.allow_metric_conversion,
      options: {
        color: 'grey lighten-3',
        width: 400,
        zIndex: 8000,
        noconfirm: false,
      },
    };
  },
  props: ['sizeChart'],
  computed: {
    isChartPublished() {
      //this.publish = this.sizeChart.published;
      //return this.sizeChart.published;
      return this.$store.getters.getChart(this.sizeChart.id).published
    },
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    async duplicateSizeChart(id) {
      //console.log("duplicate"+id);
      this.$emit("alert_data", {
          msg: "DUPLICATING...",
          status: "success",
        });

      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + '/template/duplicate', {
          params: { chart_id: id, shop: localStorage.getItem('shop') },
        })
        .then((response) => (this.chart = response.data))
        .catch((error) => console.log(error));
      if (this.chart != false) {
        //this.$emit("update_user_charts");
        this.$emit("alert_data", {
          msg: "SIZE CHART DUPLICATED",
          status: "success",
        });
        console.log("duplicateSizeChart on Sizechart line this.chart = ", this.chart)
        
        this.chart.title = this.chart.name;

        this.$store.dispatch("addSizeChart", this.chart);

      }
      else {

        this.$emit("alert_data", {
          msg: "ERROR DUPLICATING CHART",
          status: "success",
        });

      }

      this.$store.dispatch("resetInfiniteLoading");


    },
    async toggleAllowMetricConversion(id, newVal) {
      await this.$http
        .post(
          process.env.VUE_APP_SERVER_URL + '/template/upsert',
          {
            chart_id: id,
            shop: localStorage.getItem('shop'),
            allow_metric_conversion: newVal,
          },
          {
            params: {
              allow_metric_conversion: 1,
            },
          }
        )
        .then((response) => {
          this.$emit('update_chart_state', response.data);
          this.$emit('alert_data', {
            msg: 'Metric conversion toggled!',
            status: 'success',
          });
        })
        .catch((error) => console.log(error));
    },
    async delRecord(id) {
      if (
        await this.open(
          "Delete",
          "Are you sure you want to delete this size chart?"
        )
      ) {
        //console.log("delete");
        this.deleteSizeChart(id);
      }
    },
    async deleteSizeChart(id) {
       this.$emit("alert_data", {
          msg: "DELETING...",
          status: "success",
        });


      await this.$http
        .delete(process.env.VUE_APP_SERVER_URL + '/template/chart', {
          data: { chart_id: id, shop: localStorage.getItem('shop') },
        })
        .then((response) => (this.chart = response.data))
        .catch((error) => console.log(error));
      if (this.chart == true) {
        this.$emit("update_user_charts");
        this.$emit("alert_data", {
          msg: "SIZE CHART DELETED",
          status: "success",
        });
        this.$store.dispatch("deleteSizeChart", id);
      }

      else {
        this.$emit("alert_data", {
          msg: "ERROR DELETING CHART",
          status: "success",
        });
      }
      
      this.$store.dispatch("resetInfiniteLoading");

    },
    async products(id) {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + '/template/chart', {
          params: { chart_id: id, shop: localStorage.getItem('shop') },
        })
        .then((response) => (this.chart = response.data))
        .catch((error) => console.log(error));
      if (this.chart.status != 'error' || this.chart.id != null) {
        console.log(this.chart);
        if (this.chart.template_text != null) {
          this.chart.template_text = this.chart.template_text.replace(/\\n/g, '');
        }
        this.currentChart = {
          chart_id: this.chart.id,
          id: this.chart.id,
          title: this.chart.name,
          published: this.chart.published,
          content: this.chart.chart_text,
        };

        //Added by Elena to create chip//
        this.chartdata.assoclist = {
          products: this.products,
          collections: this.collections,
        };

        localStorage.setItem('chartdata', JSON.stringify(this.currentChart));
        this.$router.push({ name: 'LinkProducts' });
      }
    },
    can_publish(id) {
      //this.disabled = true
      //this.publish = !this.publish;
      let result = this.$emit('canpublish', id);
      //this.disabled = true
    },
  },
};
</script>

<style lang="scss">
.alignleft {
  text-align: left !important;
}
.size-chart-content {
  &.v-list-item__content > * {
    flex: unset;
    margin: 0 12px;
  }
}
</style>
