<template>
  <div class="background">
    <v-container class="pt-0">
      <v-card
        flat
        height="600"
        class="d-flex justify-center align-center"
        v-if="this.isDashboardLoading"
      >
        <v-progress-circular
          pt-16
          color="#F1B24A"
          indeterminate
          width="8"
          size="70"
        >
        </v-progress-circular>
      </v-card>
      <ListSizeCharts v-else-if="this.showSizeChartList" />
      <!-- <NoSizeCharts v-show="this.showCreateButton" /> -->
      <EnableApp v-if="this.showEnableButton"></EnableApp>

      <SmartSizeFooter></SmartSizeFooter>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ListSizeCharts from "@/components/Home/ListSizeCharts";
import SearchBar from "@/components/Home/SearchBar";
import NoSizeCharts from "@/components/Home/NoSizeCharts";
import CreateChart from "@/components/Home/CreateChart";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

import CountdownWidget from "@/components/Home/CountdownWidget";

const urlParams = new URLSearchParams(window.location.search);
let shop = urlParams.get("shop");
let authToken = urlParams.get("auth_token");

if (shop != null && localStorage.getItem("shop") != shop) {
  localStorage.setItem("shop", shop);
  //console.log(shop);
} else {
  //console.log("+ "+localStorage.getItem('shop'));
  shop = localStorage.getItem("shop");
}

if (authToken) {
  localStorage.setItem("smart_size_token", authToken);
}

export default {
  name: "Home",
  components: {
    ListSizeCharts,
    SearchBar,
    CreateChart,
    NoSizeCharts,
    SmartSizeFooter,

    CountdownWidget,
  },
  beforeMount() {
    this.$store.dispatch("resetInfiniteLoading");
  },
  computed: {
    isDashboardLoading() {
      return this.$store.getters.getisDashboardLoading;
    },
    showCreateButton() {
      return (
        !this.$store.getters.getisDashboardLoading && //Dashboard is not loading
        this.$store.state.sizeCharts.length == 0 //Chart list is empty
      );
    },
    showSizeChartList() {
      return !this.$store.getters.getisDashboardLoading; //Dashboard is not loading
    },
    showEnableButton() {
      return (
        !this.$store.getters.getisDashboardLoading && //Dashboard is not loading
        this.$store.state.sizeCharts.length != 0 //Chart list is not empty
      );
    },
  },
};
</script>

<style>
#size-charts .v-list-item__title {
  text-align: left;
}
#size-charts2 .v-list-item__title {
  text-align: left;
}

#size-charts2 .v-list-item__content {
  flex: 0 0 40%;
  max-width: 40%;
}
#tinymce.table_odd_stripes tr:nth-child(even) {
  background: #ccc !important;
}

#tinymce.table_odd_stripes tr:nth-child(odd) {
  background: #fff !important;
}

#myPreview .table_odd_stripes tr:nth-child(odd) {
  background: #f2f2f2 !important;
}
#myPreview .table_odd_stripes tr:nth-child(even) {
  background: #fff !important;
}
#myPreview .table_even_stripes tr:nth-child(even) {
  background: #f2f2f2 !important;
}
#myPreview .table_even_stripes tr:nth-child(odd) {
  background: #fff !important;
}
#myPreview .table_bottom_border {
  border-collapse: collapse;
  border: none !important;
}
#myPreview .table_bottom_border tr {
  border-bottom: 2px solid #cdd3d9;
}
#app {
  margin-top: 0px !important;
}

@media (max-width: 600px) {
  #chart-list .v-list-item {
    flex-direction: column !important;
    /* flex-wrap: wrap !important; */
  }
}
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>