<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" sm="8">
        <h1 left class="text-left">Dashboard</h1>
      </v-col>

      <v-col cols="12" sm="4" class="newChart">
        <!--   <v-btn
          @click="goCare"
          class="mx-2 my-2"
          width="200px"
          height="45px"
          depressed
          dark
          color="#000000"
          ><div class="font-weight-black">New Care Card</div></v-btn
        > -->
        <v-btn
          @click="goNew"
          class="mx-2 my-2"
          width="200px"
          height="45px"
          dark
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>

          <div>New Size Chart</div>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goNew() {
      this.$router.push({ name: "Templates" });
    },
    goCare() {
      this.$router.push({ name: "TemplatesCare" });
    },
  },
};
</script>

<style scoped>
.newChart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  .newChart {
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}
</style>
