<template>
  <div>
    <v-alert text black>
      <v-row align="center">
        <v-col class="grow">
          Our pricing policy will change from 01.04.2023.
        </v-col>
        <v-col class="shrink">
          <v-btn @click="openNewPage"> See new pricing </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
export default {
  methods: {
    openNewPage() {
      window.open(
        "https://smartsize.io/smartsize-pricing-01-04-2023",
        "_blank"
      );
    },
  },
};
</script>